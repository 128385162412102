import { makeAutoObservable, runInAction } from 'mobx'
import services from '@/services/account'
import { isServer } from '@/utils/isServer'

class GlobalStore {
  // token
  token: string = ''
  // userinfo
  id: string = ''
  last_login: string = ''
  is_superuser: boolean = false
  username: string = ''
  first_name: string = ''
  last_name: string = ''
  status: number = -1
  email_verified: boolean = false
  language_type: number = 0
  email: string = ''
  is_staff: boolean = false
  date_joined: string = ''
  wallet_ext: string = ''
  wallet_nonce: string = ''
  staff_type: number = -1
  name: string = ''
  referral_points: number = 0
  referral_vip_level: number = 0
  is_password_set: boolean = false
  consent_clause: boolean = true
  hasResponded: boolean = false
  balance: string = ''

  constructor() {
    makeAutoObservable(this)
    if (!isServer) {
      this.token = window.localStorage.getItem('rns-token')
    }
  }

  setToken = (token) => {
    this.token = token
  }

  removeToken = () => {
    this.token = ''
  }

  profile = async () => {
    const result = await services.profile()

    if (result.status.code === 200) {
      const data = result.data.auth
      runInAction(() => {
        this.id = data.id
        this.last_login = data.last_login
        this.is_superuser = data.is_superuser
        this.name = data.name
        this.first_name = data.first_name
        this.last_name = data.last_name
        this.email = data.email
        this.is_staff = data.is_staff
        this.date_joined = data.date_joined
        this.wallet_ext = data.wallet_ext || ''
        this.wallet_nonce = data.wallet_nonce
        this.staff_type = data.staff_type
        this.status = data.status
        this.email_verified = data.email_verified
        this.language_type = data.language_type
        this.referral_points = data.referral_points
        this.referral_vip_level = data.referral_vip_level
        this.is_password_set = data.is_password_set
        this.consent_clause = data.consent_clause
        this.hasResponded = true
        this.balance = data.balance
        // avatar: null
        // name: ""
        // nonce: 0
        // referral_code: "Y6uM0bDa"
        // referral_points: 0
        // referred_by: null
        // rns_wallet: 558
        // stripe_customer_id: "cus_LCebf4HXi6lLNZ"
      })

      setTimeout(() => {
        // @ts-ignore
        window.LiveChatWidget && window.LiveChatWidget.call('set_customer_email', data.email)
      }, 2000)
    }
  }
}

export default new GlobalStore()
