import axios from './configuredAxios'

const services = {
  /**
   * @description checkIp
   */
  checkIp: () => axios.get('/account/check_ip/'),

  /**
   * @description trackUser
   */
  trackUser: () => axios.get('/statistics/track-user/'),
}

export default services
